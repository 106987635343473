import { useContractReader } from "eth-hooks";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Wallet({ address, readContracts }) {
  const [yourCollectibles, setYourCollectibles] = useState([]);
  const [comingSoonCollectibles, setComingSoonCollectibles] = useState([]);
  const balance = useContractReader(readContracts, "TripsNFT", "balanceOf", [address]);
  const [showZeroBalance, setShowZeroBalance] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    setShowZeroBalance(!balance || balance == 0);
    if (!balance || balance == 0) {
      setYourCollectibles([]);
      setComingSoonCollectibles([]);
    }
    const updateYourCollectibles = async () => {
      let collectiblesCount = yourCollectibles.length + comingSoonCollectibles.length;
      if (collectiblesCount < balance) {
        setShowLoading(true);
        let collectibleUpdate = [];
        let comingSoonUpdate = [];
        for (let tokenIndex = 0; tokenIndex < balance; tokenIndex++) {
          try {
            // console.log("Getting token index", tokenIndex);
            const tokenId = await readContracts.TripsNFT.tokenOfOwnerByIndex(address, tokenIndex);
            // console.log("tokenId", tokenId);
            const tokenURI = await readContracts.TripsNFT.tokenURI(tokenId);
            // console.log("tokenURI", tokenURI);

            const response = await fetch(tokenURI);
            const jsonManifest = await response.json();

            // console.log("jsonManifest", jsonManifest);
            collectibleUpdate.push({ id: tokenId, uri: tokenURI, owner: address, ...jsonManifest });
          } catch (e) {
            console.log(e);
            comingSoonUpdate.push({ uri: "../images/mint/reveal-placeholder.jpg" });
          }
        }
        setYourCollectibles(collectibleUpdate);
        setComingSoonCollectibles(comingSoonUpdate);
        setShowLoading(false);
      }
    };
    updateYourCollectibles();
  }, [balance, address]);

  // Show Success
  let zeroBalance;
  if (showZeroBalance) {
    zeroBalance = (
      <div className="text-center mt-5">
        <h2 className="text-aqua">No TRIPs!</h2>
        <h5 className="text-white">If you want to go down the rabbit hole…</h5>
        <Link to="/mint" className="text-aqua">
          Go to mint
        </Link>
      </div>
    );
  }
  let loading;
  if (showLoading) {
    loading = (
      <div className="row justify-content-center">
        <div className="col wallet-loader">
          <img src="../images/wallet/loader.webp" alt="" />
          <h6 className="text-white text-center mt-3">Loading...</h6>
        </div>
      </div>
    );
  }

  function fixCatepillar(word) {
    let newWord = word === "Catepillar" ? "Caterpillar" : word;
    return <>{newWord}</>;
  }

  return (
    <div className="container-fluid">
      <div className="row wallet-content-container">
        <h4 className="text-center text-white osbourne">TRIP Wallet.</h4>
        {loading}
        {zeroBalance}
        {yourCollectibles.map((collectible, index) => {
          return (
            <div className="col text-center mt-5 mb-2">
              <p className="text-white mt-2">{collectible.name}</p>
              <img className="reveal-placeholder" src={collectible.image} alt="" />
              <p className="text-white mt-2 mb-0">Rank: {collectible.rank} (out of 3,333)</p>
              <p className="text-white m-0">Rarity Score: {collectible.rarity} (based on 5 attributes)</p>
              <div className="row attribute-container mx-auto justify-content-center">
                {collectible.attributes.map((attribute, index) => {
                  return (
                    <div className="col-6">
                      <div className="trait-box mx-auto">
                        <p className="m-0 text-uppercase attribute-value-type">{attribute.trait_type}</p>
                        <p className="text-white m-0 attribute-value">{fixCatepillar(attribute.value)}</p>
                        <p className="text-aqua m-0 attribute-percent">({attribute.percentOwned}% have this trait)</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        {comingSoonCollectibles.map((collectible, index) => {
          return (
            <div className="col text-center mt-5 mb-2">
              <p className="text-white mt-2">Reveal will occur soon!</p>
              <img className="reveal-placeholder" src={collectible.uri} alt="" />
            </div>
          );
        })}
      </div>
    </div>
  );
}
