import React from "react";
import holeBkgd from "../hole-bkgd.svg";
import { Link } from "react-router-dom";

// displays a page header

export default function Home() {
  return (
    <div>
      {/* TOP CONTENT */}
      <div className="container">
        <div className="row top-content-container">
          <div className="col">
            <h4 className="text-center text-white m-0">Take a TRIP</h4>
            <h1 className="text-center text-white">Down The Rabbit Hole</h1>
            {/* timer */}
            {/* <div className="text-center">Time Here</div> */}
            <div className="mx-auto text-center">
              <h3 className="text-secondary">SOLD OUT!</h3>
            </div>
            <div className="hero-image">
              <img src="images/rabbithole-hero.png" alt="Rabbit Hole NFTs" />
            </div>
          </div>
          <object className="hole-bkgd" data={holeBkgd} />
        </div>
      </div>
      {/* Discription Section */}
      <div className="what-section">
        <div className="container">
          <div className="row text-center content">
            <div className="col">
              <h2 className="text-white mb-3">(🎩,🎩)</h2>
            </div>
            <div className="text-center">
              <h5 className="text-white mx-auto">
                The Rabbit Hole is a collection of 3,333 unique NFTs (called TRIPs) on the Avalanche (AVAX) blockchain,
                each tripping through space and $TIME. Each is backed by The Rabbit Hole $TIME treasury and each holder
                is able to claim a percentage of the treasury rebase every 30 days.
              </h5>
            </div>
          </div>
        </div>
        {/* SLIDESHOW */}
        <div className="nft-slideshow mb-5">
          <div className="mover-1"></div>
          <div className="mover-2"></div>
        </div>
      </div>
      {/* ABOUT SECTION */}
      <div className="container">
        <div className="row about-section d-flex align-items-center">
          <div className="col-12 col-md-6">
            <div className="mx-4">
              <h2 className="text-white osbourne">3,333 unique TRIPs</h2>
              <p className="text-white pr-lg-4">
                TRIPs are unique digital art that have been generated from a mixture of different traits. These traits
                represent memes and items from #FrogNation and the community of Wonderland Money.
                <br />
                <br />
                If you dare take a trip down the rabbit hole and mint a TRIP the cost is 0.03 $TIME. To start there will
                be 3,333 TRIPs.
                <br />
                <br />
                After 1,111 TRIPs are sold the price will raise to 0.04 $TIME.
                <br />
                Once 2,222 TRIPs are sold the price will rise to 0.05 $TIME.
                <br />
                The last epoch the price will rise by 0.01 $TIME after 370 are sold.
                <br />
                <br />
                We reserved 33 TRIPs for giveaways, community members and the team.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center mt-4 mt-md-0">
            <img className="about-image" src="images/home/about-image.jpg" alt="About Rabbit Hole NFTs" />
          </div>
        </div>
      </div>

      {/* MINT GRAPHIC */}
      <div className="container">
        <div className="row mint-section text-center">
          <div className="col">
            <img className="w-100 d-none d-md-inline" src="images/home/mint-graphic.svg" alt="mint price" />
            <img className="w-100 d-md-none" src="images/home/mint-graphic-m.svg" alt="mint price" />
          </div>
        </div>
      </div>

      {/* FAQ */}
      <div className="container">
        <div className="position-relative">
          <img className="faq-bkgd" src="images/home/faq-bkgd.svg" alt="" />
        </div>
        <div className="row pt-5">
          <div className="col faq-content">
            <h2 className="osbourne text-white text-center mt-5 mb-3">FAQs</h2>
            <div className="accordian">
              {/*FAQ 1*/}
              <div className="card my-2">
                <button
                  className="btn btn-link btn-block"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">What is The Rabbit Hole?</h5>
                  </div>
                </button>

                <div id="collapseOne" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div className="card-body">
                    <p>
                      The Rabbit Hole is the first NFT project based on{" "}
                      <a href="https://www.wonderland.money/" target="_blank">
                        wonderland.money
                      </a>
                      . Each NFT is backed by the value of $TIME in the RabbitHole Treasury.
                    </p>
                  </div>
                </div>
              </div>

              {/*FAQ 2*/}
              <div className="card my-2">
                <button
                  className="btn btn-link btn-block"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">How do I participate in The Rabbit Hole?</h5>
                  </div>
                </button>

                <div id="collapseTwo" className="collapse hide" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div className="card-body">
                    <p>
                      TRIPs (NFTs) are unique digital art that have been generated from a mixture of different traits.
                      These traits represent memes and items from #FrogNation and the community of Wonderland Money.
                      <br />
                      <br />
                      If you dare take a trip down the rabbit hole, simply approve $TIME, and mint a TRIP. The cost is
                      detailed below in "What is the price of each NFT?" FAQ!
                    </p>
                  </div>
                </div>
              </div>

              {/*FAQ 3*/}
              <div className="card my-2">
                <button
                  className="btn btn-link btn-block"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                >
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">How can I benefit from The Rabbit Hole?</h5>
                  </div>
                </button>
                <div id="collapseThree" className="collapse hide" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div className="card-body">
                    <p>
                      {`
                        The main benefit for The Rabbit Hole NFT holders comes from getting rewards in $MEMO for holding
                        the NFT. The $TIME from the sale is staked (🎩,🎩) into wonderland.money and 40% of the
                        rebased amount of $MEMO will be claimable back to the holders of The Rabbit Hole NFT every
                        30 days. Essentially, we are allowing claims of 40% of the rebase reward accumulated over the
                        30 day period.`}
                    </p>
                  </div>
                </div>
              </div>

              {/*FAQ 4*/}
              <div className="card my-2">
                <button
                  className="btn btn-link btn-block"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseFour"
                >
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">Who created The Rabbit Hole?</h5>
                  </div>
                </button>

                <div id="collapseFour" className="collapse hide" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div className="card-body">
                    <p>
                      We’re a group of developers, artists, enthusiasts and cypherpunks dedicated to advancing open and
                      inclusive finance. We are part of the frog nation and want to see everyone win so that we can do
                      good for the world. We are not affiliated with or a part of the wonderland.money team. We are huge
                      fans of frog nation 🐸 and doing our part to make the world a better place.
                    </p>
                  </div>
                </div>
              </div>

              {/*FAQ 5*/}
              <div className="card my-2">
                <button
                  className="btn btn-link btn-block"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="true"
                  aria-controls="collapseFive"
                >
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">Why create another NFT project? Isn't the market already so saturated?</h5>
                  </div>
                </button>

                <div id="collapseFive" className="collapse hide" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div className="card-body">
                    <p>
                      We want to make $TIME more valuable and we want wonderland money to grow into its own ecosystem.
                      We want to be the first to build on top of the community. We want to lock $TIME up! We also have a
                      team who likes to build and create. We stay on top of current trends in the crypto space. Also, we
                      love art, we think NFTs are the future. We think we have an idea that can benefit both $TIME
                      holders, NFT holders, and do good for the space and frog nation.
                    </p>
                  </div>
                </div>
              </div>

              {/*FAQ 6*/}
              <div className="card my-2">
                <button
                  className="btn btn-link btn-block"
                  data-toggle="collapse"
                  data-target="#collapseSix"
                  aria-expanded="true"
                  aria-controls="collapseSix"
                >
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">How can you get involved?</h5>
                  </div>
                </button>

                <div id="collapseSix" className="collapse hide" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div className="card-body">
                    <p>
                      Be a part of the community, give feedback, and help promote the project. We are building this
                      project out in the open and with the help of Frog nation. We will want feedback, ideas, and
                      anything else that you might think of that could help this project.
                    </p>
                  </div>
                </div>
              </div>

              {/*FAQ 7*/}
              <div className="card my-2">
                <button
                  className="btn btn-link btn-block"
                  data-toggle="collapse"
                  data-target="#collapseSeven"
                  aria-expanded="true"
                  aria-controls="collapseSeven"
                >
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">What is the price of each NFT?</h5>
                  </div>
                </button>

                <div id="collapseSeven" className="collapse hide" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div className="card-body">
                    <p>
                      There are 3,333 TRIP NFTs available to be minted. The price levels are broken up into 3 epochs
                      with the third epoch again also broken up in 3 epochs. The first epoch of 1,111 TRIPs will be 0.03
                      $TIME. The second epoch of 1,111 TRIPs will be 0.04 $TIME. The first/third epoch of 370 TRIPs will
                      be 0.05 $TIME. The second/third epoch of 370 TRIPs will be 0.06 $TIME. The final third/third epoch
                      of 371 TRIPs will be 0.07 $TIME.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
