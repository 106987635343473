import React from "react";
import Blockies from "react-blockies";
import { useLookupAddress } from "eth-hooks/dapps/ens";

// changed value={address} to address={address}

/*
  ~ What it does? ~

  Displays an address with a blockie image and option to copy address

  ~ How can I use? ~

  <Address
    address={address}
    ensProvider={mainnetProvider}
    blockExplorer={blockExplorer}
    fontSize={fontSize}
  />

  ~ Features ~

  - Provide ensProvider={mainnetProvider} and your address will be replaced by ENS name
              (ex. "0xa870" => "user.eth")
  - Provide blockExplorer={blockExplorer}, click on address and get the link
              (ex. by default "https://etherscan.io/" or for xdai "https://blockscout.com/poa/xdai/")
  - Provide fontSize={fontSize} to change the size of address text
*/

const blockExplorerLink = (address, blockExplorer) =>
  `${blockExplorer || "https://snowtrace.io/"}${"address/"}${address}`;

export default function Address(props) {
  const address = props.value || props.address;

  const ens = useLookupAddress(props.ensProvider, address);

  let displayAddress = address?.substr(0, 6);

  if (!displayAddress) {
    displayAddress = "";
  } else if (ens && ens.indexOf("0x") < 0) {
    displayAddress = ens;
  } else if (props.size === "short") {
    displayAddress += "..." + address?.substr(-4);
  } else if (props.size === "long") {
    displayAddress = address;
  }

  const etherscanLink = blockExplorerLink(address, props.blockExplorer);

  return (
    <div className="row d-flex align-items-center">
      <div className="col pl-3">
        <img src="/images/global/connected-status.svg" alt="" />
        {/* <Blockies seed={address?.toLowerCase()} size={8} scale={2} /> */}
      </div>
      <div className="col eth-address">
        <a className="etherscan-link d-flex" href={etherscanLink} target="_blank">
          {displayAddress}
          <div className="px-3">
            <svg width="18px" height="15px" viewBox="0 0 18 15">
              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group" transform="translate(-2.000000, -3.000000)" fill="#3BFFE2" fillRule="nonzero">
                  <path
                    d="M18.6666667,6.33333333 L19.5,6.33333333 L19.5,14.6666667 L18.6666667,14.6666667 L18.6666667,17.1666667 C18.6666667,17.626904 18.2935706,18 17.8333333,18 L2.83333333,18 C2.37309604,18 2,17.626904 2,17.1666667 L2,3.83333333 C2,3.37309604 2.37309604,3 2.83333333,3 L17.8333333,3 C18.2935706,3 18.6666667,3.37309604 18.6666667,3.83333333 L18.6666667,6.33333333 Z M17,14.6666667 L12,14.6666667 C9.69881354,14.6666667 7.83333333,12.8011865 7.83333333,10.5 C7.83333333,8.19881354 9.69881354,6.33333333 12,6.33333333 L17,6.33333333 L17,4.66666667 L3.66666667,4.66666667 L3.66666667,16.3333333 L17,16.3333333 L17,14.6666667 Z M17.8333333,13 L17.8333333,8 L12,8 C10.6192881,8 9.5,9.11928813 9.5,10.5 C9.5,11.8807119 10.6192881,13 12,13 L17.8333333,13 Z M12,9.66666667 L14.5,9.66666667 L14.5,11.3333333 L12,11.3333333 L12,9.66666667 Z"
                    id="Shape"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
        </a>
      </div>
    </div>
  );
}
